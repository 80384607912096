import { Container, Row } from "react-bootstrap";
import React from "react";

function EducationAndWork(props) {
	return (
		<article className="bg-skills py-5" id="education">
			<Container className="mt-5 pt-2 text-white">
				<h1 className="display-1 text-center">Education</h1>
				<Row className="fs-5">
					<div className="col-md-5 offset-md-1">
						<h3>Computer science AP, 2018-2021</h3>
						<ul>
							<li>System Design</li>
							<li>System Integration</li>
							<li>Software Development</li>
							<li>CI/CD Docker, AWS, Heroku, Jenkins</li>
							<li>Testing</li>
							<li>Prototyping</li>
							<li>Polymorphism, Inheritance</li>
							<li>
								Patterns: MVC, Singleton, Factory & Iterator
								pattern
							</li>
							<li>Principles: DRY, KISS, SOLID, GRASP</li>
							<li>Project management</li>
							<li>
								Agile development: Scrum, XP & Pair programming
							</li>
							<li>Project estimation(Cost, Development time)</li>
							<li>UML & UP</li>
							<li>Java & Spring</li>
							<li>Full Stack Node.js Course</li>
							<li>
								Advanced Python 3 course, Data science,
								optimization etc.
							</li>
							<li>SaaS, IaaS, PaaS</li>
							<li>Advanced Networking</li>
							<li>IT-Security</li>
							<li>Protocols, compliance, GDPR</li>
						</ul>
					</div>
					<div className="col-md-5">
						<h3>Web Integration, 2014-2016</h3>
						<ul>
							<li>Web development</li>
							<li>Web Integration</li>
							<li>UI/UX</li>
							<li>PHP</li>
							<li>C#</li>
							<li>MySQL</li>
							<li>ASP.NET</li>
							<li>Networking</li>
							<li>Webserver Administration</li>
							<li>Innovation</li>
							<li>Entrepreneurship</li>
							<li>E-Commerce principles</li>
							<li>Umbraco</li>
						</ul>
					</div>
				</Row>
				<h1 className="display-1 text-center" id="work">
					Work
				</h1>
				<Row>
					<h3 className="text-center my-3 mb-5 col-md-6 offset-md-3 ">
						IT-Coordinator, Simply.com (Previously Unoeuro.com &
						Surftown.com), 2016-2019
					</h3>
				</Row>
				<Row className="fs-5">
					<div className="col-md-5 offset-md-1">
						<h3 className="fw-bold">Tasks</h3>
						<ul>
							<li>Customer support</li>
							<li>Live chat</li>
							<li>Technical support</li>
							<li>Consulting</li>
							<li>Phone support</li>
							<li>Web Design</li>
							<li>DNS & Website Migrations</li>
							<li>DNS services</li>
							<li>Troubleshooting solutions</li>
							<li>Creating & translating support articles</li>
							<li>GDPR</li>
							<li>Data restoration</li>
							<li>Restoration of hacked sites</li>
							<li>Payments & transactions bookkeeping</li>
						</ul>
					</div>
					<div className="col-md-5">
						<h3 className="fw-bold">Specifics</h3>
						<ul>
							<li>DNS Administration & domain management</li>
							<li>Wordpress</li>
							<li>Joomla</li>
							<li>Prestashop</li>
							<li>Umbraco</li>
							<li>In-house CMS & webshop system</li>
							<li>Windows server management</li>
							<li>Linux server management</li>
							<li>Legacy migrations to newer servers.</li>
							<li>Yourpay</li>
							<li>Swift</li>
						</ul>
					</div>
				</Row>
			</Container>
		</article>
	);
}

export default EducationAndWork;
