import { Container, Nav, Navbar } from 'react-bootstrap';
//import logo from "./language_black_24dp.svg";
function TopMenu(props){
  /*
  const languageIcon = (
    <img src={logo} alt="Language Icon" />
  );
  */
  console.log(props.words);
   return (
     <Navbar bg="light" expand="lg" fixed="top" className="px-3 ">
       <Container>
       <Navbar.Brand href="/">Portfolio</Navbar.Brand>
       <Navbar.Toggle aria-controls="basic-navbar-nav" />
       <Navbar.Collapse id="basic-navbar-nav">
         <Nav style={{marginRight:"auto"}}>
            <Nav.Link href="#aboutme" >{props.words.aboutMe}</Nav.Link>
            <Nav.Link href="#education">{props.words.education}</Nav.Link>
            <Nav.Link href="#work">{props.words.work}</Nav.Link>
            <Nav.Link href="#skills">{props.words.skills}</Nav.Link>
            <Nav.Link href="#projects">{props.words.projects}</Nav.Link>
            <Nav.Link href="#contact">{props.words.contact}</Nav.Link>
         </Nav>
         {/*
         <Nav inline>
            <NavDropdown title={languageIcon}>
              <NavDropdown.Item onClick={() => props.changelanguage("DA")}>Dansk</NavDropdown.Item>
              <NavDropdown.Item onClick={() => props.changelanguage("EN")}>English</NavDropdown.Item>
            </NavDropdown>
         </Nav>
          */}
       </Navbar.Collapse>
       </Container>
     </Navbar>
     
   )
}

export default TopMenu;