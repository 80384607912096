import React from 'react';
import { Container} from 'react-bootstrap';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom'

import TopMenu from './components/TopMenu';
import Projects from "./components/Projects";
import Skills from './components/Skills';
import EducationAndWork from './components/EducationAndWork';
import About from './components/About';
import Contact from './components/Contact';

import './assets/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import texts from './texts.json';
import Daimonia from './components/Daimonia';


const default_language = texts.languages.find(language => language.name === "EN");

class App extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      language: default_language.name,
      htmlText: default_language.textData
    };
  }
  handle_language_change(language_name){
    const new_language = texts.languages.find(language => language.name === language_name);
    console.log(new_language.name);
    this.setState({
      language: new_language.name,
      htmlText: new_language.textData
    });
  }
  render(){
  return (
    <div className="F-abel">
      <TopMenu changelanguage={(language_name) => this.handle_language_change(language_name)} words={this.state.htmlText.commonWords} />
      <BrowserRouter>
      <Link to="/daimonia">Daimonia</Link>
      <main>
        
            <Switch>
              <Route path="/daimonia" component={Daimonia}>
                <Daimonia/>
              </Route>
              <Route path="/">
                <About htmlText={this.state.htmlText} />
                <EducationAndWork />
                <Skills />
                <Projects />
                <Contact />
              </Route>
            </Switch>
      

        
      </main>
      </BrowserRouter>
      <footer className="bg-dark">
        <Container>
          <p className="text-muted p-3 m-0">
            Aleksandr Sorokin © 2021
          </p>
        </Container>
      </footer>
    </div>
  );
  }
}

export default App;
