import { Container, Row } from "react-bootstrap";
import React from "react";

function Skills(props) {
	return (
		<article className="bg-pink-blue py-5" id="skills">
			<Container className="mt-5 pt-2 text-white">
				<h1 className="display-1 text-center">Skills</h1>
				<Row className="fs-5">
					<div className="col-md-5 offset-md-1">
						<h3>Frameworks, libraries & tools</h3>
						<ul>
							<li>Node.js</li>
							<li>Express.js</li>
							<li>React.js</li>
							<li>Spring</li>
							<li>Spring Boot</li>
							<li>JQuery</li>
							<li>ASP.NET Web Forms</li>
							<li>ASP.NET MVC</li>
							<li>MongoDB</li>
							<li>Visual Studio & VS Code</li>
							<li>IntelliJ</li>
							<li>MongoDB</li>
							<li>JQuery</li>
						</ul>
					</div>
					<div className="col-md-5">
						<h3>Programming languages & syntaxes</h3>
						<ul>
							<li>Javascript</li>
							<li>Java</li>
							<li>Python 2 & 3</li>
							<li>C#</li>
							<li>C</li>
							<li>NASM</li>
							<li>PHP</li>
							<li>Bash</li>
							<li>HTML</li>
							<li>CSS</li>
							<li>JSON</li>
							<li>Thymeleaf</li>
							<li>JSX</li>
						</ul>
					</div>
					<div className="col-md-5 offset-md-1">
						<h3>Methodologies, proccesses, engineering,</h3>
						<ul>
							<li>System Design</li>
							<li>System Development</li>
							<li>System Engineering</li>
							<li>Testing</li>
							<li>Agile development(Scrum)</li>
							<li>Project management</li>
							<li>RESTful API's</li>
							<li>
								Database design, optimization & maintainance
							</li>
							<li>
								Optimizing performance, memory usage,
								read/write, network activity speed for
								applications, websites, systems & algorithms{" "}
							</li>
							<li>Networking</li>
							<li>Documentation of larger systems</li>
							<li>UML Diagrams</li>
							<li>
								Use cases, activity diagrams, data flow diagrams
							</li>
						</ul>
					</div>
					<div className="col-md-5">
						<h3>Languages</h3>
						<ul>
							<li>Danish: Perfect</li>
							<li>English: Perfect</li>
							<li>Russian: Decent</li>
							<li>Swedish & Norwegian: Decent</li>
						</ul>
						<h3>Others</h3>
						<ul>
							<li>UX/UI</li>
							<li>Entrepreneurship</li>
							<li>Innovation</li>
							<li>Photography</li>
							<li>Image editing (Photoshop)</li>
							<li>Drawing (Krita)</li>
						</ul>
					</div>
				</Row>
			</Container>
		</article>
	);
}

export default Skills;
