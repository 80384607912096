import { Container, Row } from "react-bootstrap";
import React from "react";

function Projects(props) {
	return (
		<article className="py-5 bg-projects" id="projects">
			<Container className="mt-5 pt-2 text-white">
				<h1 className="display-1 text-center">Projects</h1>
				<Row>
					<h3 className="text-center my-3 mb-5 col-md-6 offset-md-3 ">
						Here are some of my personal projects, as well as
						projects I've collaborated on and the tools used to
						develop them.
					</h3>
				</Row>
				<Row className="fs-5">
					<div className="col-md-6">
						<h3 className="fw-bold">Portfolio (This page)</h3>
						<p>
							This is my personal portfolio, made for networking,
							and all those interested in me personally.
						</p>
						<ul>
							<li>Node.js</li>
							<li>React.js</li>
							<li>React Bootstrap</li>
							<li>Serverless</li>
							<li>Netlify forms</li>
							<li>Git</li>
							<li>JSON</li>
							<li>Javascript</li>
							<li>HTML</li>
							<li>CSS</li>
						</ul>
					</div>
					<div className="col-md-6">
						<h3 className="fw-bold">Barbershop(Collab)</h3>
						<p>
							An online booking site for a barber with multiple
							service bookings, product reservation user-friendly
							point & click calendar booking. Features include
							newsletter signup, SMS notifications for customers,
							automatic daily schema creation sent to barber by
							e-mail or SMS, and many more.
						</p>
						<ul>
							<li>Node.js</li>
							<li>Express.js</li>
							<li>Bootstrap</li>
							<li>MySQL</li>
							<li>Node Mailer</li>
							<li>Twilio</li>
							<li>Heroku (SSL)</li>
							<li>Git</li>
							<li>Objection.js</li>
							<li>Knex.js (Database Schemas & Migrations)</li>
							<li>
								Escape-html, Express-rate-limit,
								Bcrypt(Security)
							</li>
							<li>NodeCron (Automation)</li>
						</ul>
					</div>
				</Row>
				<Row className="fs-5">
					<div className="col-md-6">
						<h3 className="fw-bold">Forum</h3>
						<p>
							A simple but powerful forum, able to create multiple
							forums, categories & topics. Includes likes,
							notifications, viewcount, banning users, deleting
							posts, threads, e-mail verification, password
							recover & BBCode.
						</p>
						<ul>
							<li>Java</li>
							<li>Rest API</li>
							<li>MySQL</li>
							<li>Thymeleaf</li>
							<li>Spring Data JPA</li>
							<li>Bootstrap</li>
							<li>JQuery</li>
							<li>Spring Security</li>
							<li>Spring Mail</li>
							<li>JUnit (Unit Testing)</li>
							<li>MVC</li>
						</ul>
					</div>
					<div className="col-md-6">
						<h3 className="fw-bold">Kino Empire</h3>
						<p>
							Theatre system, booking tickets to a movie with seat
							reservation, easily see all the seats & their
							position, pick multiple seats & checkout.
							Administrators can see seats taken for a given
							screening & have user friendly backend for creating
							new schedules for movies, removing old ones &
							changing existing time schedule. Customers
							temporarily reserves seats while browsing, to ensure
							their seats before checkout.
						</p>
						<ul>
							<li>Java</li>
							<li>MySQL</li>
							<li>Spring Boot</li>
							<li>Thymeleaf</li>
							<li>Bootstrap</li>
							<li>Transactions in MySQL</li>
							<li>JQuery</li>
							<li>Spring Security</li>
							<li>JDBC</li>
							<li>MVC</li>
							<li>Hibernate</li>
						</ul>
					</div>
				</Row>
				{/*
            <Row className="fs-5">
            <div className="col-md-6">
                <h3 className="fw-bold">
                  Web scraper
                </h3>
                <p>
                  Automation tool that scraped a website for multiple informations then automatically updated a backend system with product infomation, stock & prices
                </p>
                <ul>
                  <li>Node.js</li>
                  <li>Puppetteer.js</li>
                  <li></li>
                </ul>
              </div>
              <div className="col-md-6">
                <h3 className="fw-bold">
                  Multithreaded chat protocol
                </h3>
                <p>
                  A simple multithreaded chat protocol in the terminal with some small features like disconnecting when inactive.
                </p>
                <ul>
                  <li>Java</li>
                </ul>
              </div>
            </Row>
            */}
				<Row>
					<h3 className="text-center my-3 mb-5 col-md-6 offset-md-3 ">
						And many more...
					</h3>
				</Row>
			</Container>
		</article>
	);
}

export default Projects;
