import { Container, Row } from "react-bootstrap";
import React from "react";

function Daimonia(props) {
	return (
		<article>
			<Container className="mt-5 pt-2 text-white">
				<h1 className="display-1 text-center">test</h1>
				<Row className="fs-5">
					<h3>test2</h3>
				</Row>
			</Container>
		</article>
	);
}

export default Daimonia;
