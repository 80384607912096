import React from 'react';
import { Container, Button, Row, } from 'react-bootstrap';
//import logo from "./language_black_24dp.svg";



class Contact extends React.Component{

  constructor(props) {
    /*
  const languageIcon = (
    <img src={logo} alt="Language Icon" />
  );
  */
    super(props);
    this.state = { name: "", subject: "", email: "", message: "" };
  }
  
  
 render(){
  
   return (
    <article className="py-5" id="contact">
              <Container className="mt-5 pt-2">
                <h1 className="display-1 text-center">
                  Contact
                </h1>
                <Row>
                  <div className="col-md-10 offset-md-1">
                    <h3 className="text-center">
                      If you're interested to learn more or contact me, feel free to use the form below.
                    </h3>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-8 offset-md-2">
                    <form method="POST" name="contactform" netlify >
                      <input type="hidden" name="form-name" value="contactform" />
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input type="text" className="form-control" name="name" id="name" placeholder="Name" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email address</label>
                      <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="subject" className="form-label">Subject</label>
                      <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message" className="form-label">Message</label>
                      <textarea className="form-control" name="message" id="message" rows="3"></textarea>
                    </div>
                    <Button className="btn-lg" type="submit">Send</Button>
                    </form>
                  </div>
                </Row>
              </Container>
            </article>
      )
  }
}

export default Contact;