import React from 'react';
import { Container, Row } from 'react-bootstrap';

import projectIcon from '../assets/construction_white_24dp.svg';
import skillsIcon from '../assets/polymer_white_24dp.svg';
import schoolIcon from '../assets/school_white_24dp.svg';
import contactIcon from '../assets/account_circle_black_24dp.svg';
import workIcon from '../assets/work_white_24dp.svg';
import portrait from '../assets/portrait.jpg';

function About(props) {
	return (
		<article className="py-5 bg-school-work" id="aboutme">
			<Container>
				<Row className="mt-5 pt-4 text-light">
					<h1 className="display-1 F-Great-Vibes text-center">
						{props.htmlText.landingTitle}
					</h1>
					<Row>
						<h3 className="text-center my-3 mb-5 col-md-6 offset-md-3 ">
							{props.htmlText.landingText}
						</h3>
					</Row>
					<Row>
						<img
							src={portrait}
							alt="Portrait"
							className="Portrait rounded-circle mx-auto d-block"
						/>
					</Row>
					<h4 className="text-center justify-text my-5 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
						I'm 25 years old & live in Copenhagen, Denmark, I've
						lived in Sweden for some years while working & studying
						in Denmark. I'm a very outgoing and social person, my
						passion is programming, music & drawing, I also really
						enjoy watching series & have a special interest for
						Japanese culture.
					</h4>
				</Row>
				<Row>
					<div className="col-xl-2 offset-xl-1  text-center">
						<a
							href="#education"
							className="btn btn-lg text-light btn-success  my-3 col-12 col-md-6 col-xl-12 rounded-0"
						>
							Education <img alt="School Icon" src={schoolIcon} />
						</a>
					</div>
					<div className="col-xl-2 text-center">
						<a
							href="#work"
							className="btn btn-lg text-white btn-warning my-3 col-12 col-md-6 col-xl-12 rounded-0"
						>
							Work <img alt="Work Icon" src={workIcon} />
						</a>
					</div>
					<div className="col-xl-2 text-center">
						<a
							href="#skills"
							className="btn btn-lg text-light btn-danger  my-3 col-12 col-md-6 col-xl-12 rounded-0"
						>
							Skills <img alt="Skills Icon" src={skillsIcon} />
						</a>
					</div>
					<div className="col-xl-2 text-center">
						<a
							href="#projects"
							className="btn btn-lg text-light btn-info my-3 col-12 col-md-6 col-xl-12 rounded-0"
						>
							Projects{' '}
							<img alt="Projects Icon" src={projectIcon} />
						</a>
					</div>
					<div className="col-xl-2 text-center">
						<a
							href="#contact"
							className="btn btn-lg text-dark btn-light my-3 col-12 col-md-6 col-xl-12 rounded-0"
						>
							Contact <img alt="Contact Icon" src={contactIcon} />
						</a>
					</div>
				</Row>
			</Container>
		</article>
	);
}

export default About;
